/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import SlickSlider, {Settings as SlickSettings, SwipeDirection} from 'react-slick';
import classNames from 'classnames';
import {classes as sliderGalleryStylable} from './SliderGallery.st.css';
import {GALLERY_TYPE, keyboardEvents} from '../../../constants';
import {ProductItemWithGlobals} from '../../../common/components/ProductItem/ProductItem';
import {IPropsInjectedByViewerScript} from '../../../types/sliderGalleryTypes';
import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import {GridType, IGallerySantaProps, IProduct} from '../../../types/galleryTypes';
import autobind from 'auto-bind-es5';
import {ProductPlaceholder} from './ProductPlaceholder/ProductPlaceholder';
import s from './SliderGallery.scss';
import {withGlobals} from '../../../globalPropsContext';
import {ISliderGlobalProps} from '../../sliderGlobalStrategy';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import {Text} from 'wix-ui-tpa/cssVars';
import {NavArrowLeft} from './Arrows/NavArrowLeft';
import {NavArrowRight} from './Arrows/NavArrowRight';
import {ProductMediaDataHook} from '../../../common/components/ProductItem/ProductMedia/ProductMedia';
import {EmptyGallery} from '../../../common/components/EmptyGallery/EmptyGallery';
import {ConditionalRender} from '../../../category/components/ConditionalRender/ConditionalRender';

export type SliderGalleryProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  ISliderGlobalProps['globals']
> &
  ISliderGlobalProps;

export interface SliderGalleryState {
  currentSliderIndex: number;
  height: number;
  inBrowser: boolean;
  lastMove: 'next' | 'prev';
  width: number;
  imageHeight?: number;
}

type ExtendedSlickSlider = SlickSlider & {
  innerSlider: {
    track: {
      props: {
        currentSlide: number;
      };
    };
  };
};

export enum DataHook {
  LeftNavigationArrow = 'navigation-arrows-left-button',
  RightNavigationArrow = 'navigation-arrows-right-button',
}

interface IProductPlaceholder {
  id: string;
}

export enum ArrowsDir {
  LEFT = 'left',
  RIGHT = 'right',
}

const NavigationArrow = ({dir}: {dir: ArrowsDir}) => {
  switch (dir) {
    case ArrowsDir.LEFT:
      return <NavArrowLeft className={s.arrow} />;
    case ArrowsDir.RIGHT:
      return <NavArrowRight className={s.arrow} />;
  }
};

const NavigationControl = withGlobals(
  ({
    dir,
    onNav,
    arrowPosition,
    globals: {textsMap},
  }: {
    dir: ArrowsDir;
    onNav?(): void;
    arrowPosition?: number;
    globals: IPropsInjectedByViewerScript;
  }) => {
    const {sliderGalleryPreviousProduct, sliderGalleryNextProduct} = textsMap;
    /* istanbul ignore next: keyboard navigation is tested in e2e */
    const onKeypressNavigation = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.keyCode === keyboardEvents.ENTER.keyCode) {
        onNav();
      }
    };

    const arrowPositionPixels = arrowPosition - 9;

    return (
      <div className={classNames([s.navigationArrows, s[dir]])} style={{top: `${arrowPositionPixels}px`}}>
        <button
          aria-label={dir === 'left' ? sliderGalleryPreviousProduct : sliderGalleryNextProduct}
          data-hook={dir === 'left' ? DataHook.LeftNavigationArrow : DataHook.RightNavigationArrow}
          className={s.resetButton}
          type="button"
          onClick={onNav}
          onKeyPress={onKeypressNavigation}>
          <NavigationArrow {...{dir}} />
        </button>
      </div>
    );
  }
);

const WrapWithNavigation = ({
  withNavigationArrows,
  children,
  onNavigate,
  imageHeight,
}: {
  withNavigationArrows: boolean;
  children: React.ReactElement;
  onNavigate?(dir: ArrowsDir): void;
  imageHeight?: number;
}) => {
  return withNavigationArrows ? (
    <div style={{'--imageHeight': `${imageHeight}px`} as any}>
      <NavigationControl onNav={onNavigate && (() => onNavigate(ArrowsDir.LEFT))} dir={ArrowsDir.LEFT} />
      {children}
      <NavigationControl onNav={onNavigate && (() => onNavigate(ArrowsDir.RIGHT))} dir={ArrowsDir.RIGHT} />
    </div>
  ) : (
    children
  );
};

export class SliderGalleryComp extends React.Component<SliderGalleryProps, SliderGalleryState> {
  private _ref: HTMLDivElement;
  private _slickRef: ExtendedSlickSlider;
  private galleryKey;
  private a11yAnnouncer: Announcer;
  private resizeObserver = null;

  constructor(props: SliderGalleryProps) {
    super(props);
    this.state = this.getInitialState();
    this.galleryKey = !this.isEmptyState ? this.products[0].id : '';
    autobind(this);
  }

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('slider-announcer');
    const {registerToComponentDidLayout} = this.props.host;
    const {isEditorMode} = this.props.globals;
    registerToComponentDidLayout(this.reportAppLoaded);

    if (!this._ref) {
      return;
    }

    this.updateState();

    /* istanbul ignore next: covered in sled */
    if (this.isResponsive && isEditorMode) {
      this.resizeObserver = new ResizeObserver(this.updateDimensions);
      this.resizeObserver.observe(this._ref.parentElement);
      this.updateDimensions();
    } else if (this.gridType === GridType.AUTO || this.isResponsive) {
      window.addEventListener('resize', this.updateDimensions);
    }
  }

  /* istanbul ignore next: covered in sled */
  private updateDimensions() {
    const {isEditorMode} = this.props.globals;
    const resize = () => {
      const {clientHeight, clientWidth} = this._ref.parentElement;

      this.setState({
        height: clientHeight,
        width: clientWidth,
        ...(isEditorMode ? {imageHeight: this.getImageHeight()} : {}),
      });
    };
    if (this.isResponsive && isEditorMode) {
      requestAnimationFrame(() => {
        resize();
      });
    } else {
      resize();
    }
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
    window.removeEventListener('resize', this.updateDimensions);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  private updateState() {
    const {clientHeight, clientWidth} = this._ref.parentElement;

    this.setState(
      {
        height: clientHeight,
        width: clientWidth,
        inBrowser: true,
      },
      () => {
        this.setState({imageHeight: this.getImageHeight()});
        imagesLoaded(this._ref.querySelectorAll(`[data-hook="${ProductMediaDataHook.Images}"]`), () => {
          this.props.globals.updateLayout && this.props.globals.updateLayout();
        });
      }
    );
  }

  public componentDidUpdate(prevProps: SliderGalleryProps) {
    if (!this._ref) {
      return;
    }

    /* istanbul ignore next: swipe is tested in sled */
    if (prevProps.isLoaded !== this.props.isLoaded) {
      this.updateState();
    }

    if (this.props.globals.isInteractive) {
      this.reportAppLoaded();
    }
    this.updateSlidesKeyboardNavigation();

    if (!this.isEmptyState && this.products[0].id !== this.galleryKey) {
      this.resetNavigation();
      this.galleryKey = this.products[0].id;
    }
  }

  private resetNavigation() {
    this._slickRef.slickGoTo(0, true);
    this.setState({currentSliderIndex: 0});
  }

  private get shouldShowSlider(): boolean {
    return this.props.isLoaded;
  }

  private get isEmptyState(): boolean {
    const {
      globals: {products, isCategoryVisible},
    } = this.props;
    return !products || products.length === 0 || !isCategoryVisible;
  }

  private get products(): IProduct[] | IProductPlaceholder[] {
    const {products} = this.props.globals;
    if (!this.isEmptyState) {
      return products;
    }

    return _.range(0, this.slidesToShow).map((__, index) => ({
      id: index.toString(),
    }));
  }

  public renderEmptyState(): JSX.Element {
    return (
      <WrapWithNavigation withNavigationArrows={false}>
        <SlickSlider
          className={s.sliderGallerySlick}
          data-hook="slider-gallery-slick"
          {...this.getSlickSettings()}
          ref={(slider) => (this._slickRef = slider as ExtendedSlickSlider)}>
          {(this.products as IProductPlaceholder[]).map((product) => (
            <div key={product.id} className={s.sliderGallerySlideContainer}>
              <div data-hook="slider-gallery-slide" className={s.emptyStateSidesPadding} style={{height: '100%'}}>
                <ProductPlaceholder />
              </div>
            </div>
          ))}
        </SlickSlider>
      </WrapWithNavigation>
    );
  }

  private get currentActiveSlideIndex(): number {
    return this._slickRef && this._slickRef.innerSlider.track.props.currentSlide;
  }

  private onNavigate(target: ArrowsDir | number) {
    if (target === 'right') {
      this._slickRef.slickNext();
      this.updateCurrentActiveSliderIndex();
      this.setState({
        lastMove: 'next',
      });
    } else {
      this._slickRef.slickPrev();
      this.setState({
        lastMove: 'prev',
      });
    }
  }

  public renderSlides(): JSX.Element {
    const {inBrowser, imageHeight} = this.state;
    const {
      isSSR,
      experiments: {responsiveSliderFixItemsExceedingSpace},
    } = this.props.globals;

    const isAutoGrid = this.gridType === GridType.AUTO;

    return (
      <WrapWithNavigation
        imageHeight={imageHeight}
        withNavigationArrows={inBrowser && !this.shouldUseArrowlessMobileSlider}
        onNavigate={this.onNavigate}>
        <SlickSlider
          className={classNames(s.sliderGallerySlick, {
            [s.ssrFix]: isSSR && !this.state.width,
            [s.autoGridSlickTrack]: isAutoGrid,
          })}
          data-hook="slider-gallery-slick"
          {...this.getSlickSettings()}
          ref={(slider) => (this._slickRef = slider as ExtendedSlickSlider)}>
          {this.products.map((product, i) => (
            <div key={i} className={s.sliderGallerySlideContainer}>
              <div
                data-hook="slider-gallery-slide"
                className={classNames(s.product, {[s.fixItemsExceedingSpace]: responsiveSliderFixItemsExceedingSpace})}
                style={{height: '100%'}}>
                <ProductItemWithGlobals
                  product={product}
                  style={{height: '100%'}}
                  index={i}
                  a11yAnnouncer={this.a11yAnnouncer}
                />
              </div>
            </div>
          ))}
        </SlickSlider>
      </WrapWithNavigation>
    );
  }

  private get gridType() {
    const {
      globals: {styles, stylesParams},
    } = this.props;

    return styles.get(stylesParams.gallery_gridType);
  }

  private get isResponsive() {
    const {styles, stylesParams} = this.props.globals;
    return styles.get(stylesParams.responsive);
  }

  private get slidesToShow(): number {
    const {
      globals: {styles, stylesParams, isSSR, products},
    } = this.props;

    if (!this.state.width && isSSR) {
      // TODO: In SSR we cant get the component / screen width, this MAX_NUMBER_OF_PRODUCTS const is a hack to show only up to 30 products (maximum for standard non wide screen) - but we need to fix it EE-44230
      const MAX_NUMBER_OF_PRODUCTS = this.gridType === GridType.AUTO ? 30 : 6;
      return Math.min(MAX_NUMBER_OF_PRODUCTS, products?.length);
    }

    if (this.gridType === GridType.AUTO) {
      const minProductWidth = styles.get(stylesParams.gallery_productSize);
      const columnGap = styles.get(stylesParams.gallery_gapSizeColumn);
      const arrowsPadding = 30 * 2;
      const slidesCount = Math.floor((this.state.width - arrowsPadding) / (minProductWidth + columnGap));
      return Math.min(Math.max(slidesCount, 1), products?.length);
    }

    return styles.get(stylesParams.galleryColumns);
  }

  private loadMoreProducts() {
    const {getCategoryProducts} = this.props.globals;
    const {lastMove} = this.state;
    const currentIndex = this.currentActiveSlideIndex;

    if (!currentIndex) {
      return;
    }

    const lastNextProductIndex = this.products.findIndex((product) => (product as any).isFake);
    const lastPrevProductIndex = _.findLastIndex(this.products, (product) => (product as any).isFake);

    if (lastPrevProductIndex < 0 || lastNextProductIndex < 0) {
      return;
    }

    const prevProductsThreshold = lastPrevProductIndex + this.numOfProductsToLoad + 1;
    const nextProductsThreshold = lastNextProductIndex - this.numOfProductsToLoad;

    if (lastMove === 'prev' && currentIndex <= prevProductsThreshold) {
      const offset = Math.max(lastNextProductIndex, lastPrevProductIndex - this.numOfProductsToLoad + 1);
      const limit = Math.min(this.numOfProductsToLoad, Math.abs(lastPrevProductIndex - lastNextProductIndex + 1));
      getCategoryProducts({offset, limit});
    } else if (lastMove === 'next' && currentIndex >= nextProductsThreshold) {
      const offset = lastNextProductIndex;
      const limit = Math.min(this.numOfProductsToLoad, Math.abs(lastPrevProductIndex - lastNextProductIndex + 1));
      getCategoryProducts({offset, limit});
    }
  }

  public getSlickSettings(): SlickSettings {
    const {isSSR} = this.props.globals;
    const slidesToShow = this.slidesToShow;
    return {
      arrows: false,
      dots: false,
      centerMode: this.shouldUseArrowlessMobileSlider,
      slidesToShow,
      slidesToScroll: 1,
      speed: 400,
      lazyLoad: 'ondemand',
      onLazyLoad: this.loadMoreProducts,
      infinite: !isSSR && this.products.length > slidesToShow,
      onSwipe:
        /* istanbul ignore next: swipe is tested in e2e */
        (dir: SwipeDirection) => {
          this.setState({
            lastMove: dir === 'left' ? 'next' : 'prev',
          });
        },
      afterChange: () => {
        this.updateCurrentActiveSliderIndex();
        this.updateSlidesKeyboardNavigation();
      },
      accessibility: false,
      swipeToSlide: true,
      focusOnSelect: false,
    };
  }

  private get numOfProductsToLoad(): number {
    return this.slidesToShow * 2;
  }

  private getInitialState(): SliderGalleryState {
    const {
      globals: {
        dimensions: {height, width},
      },
    } = this.props;
    return {
      currentSliderIndex: 0,
      height,
      inBrowser: false,
      lastMove: 'next',
      width,
    };
  }

  private reportAppLoaded(): void {
    const {onAppLoaded, globals} = this.props;
    if (globals.isInteractive && typeof onAppLoaded === 'function') {
      onAppLoaded();
    }
  }

  private getImageHeight() {
    const imageContainerEl = this._ref.querySelector(`[data-hook="${ProductMediaDataHook.Images}"]`);
    if (!imageContainerEl) {
      return 0;
    }

    return imageContainerEl.clientHeight;
  }

  private renderTitle() {
    const {globals} = this.props;
    const htmlTag = globals.htmlTags.headerTextHtmlTag;
    const classes = classNames(sliderGalleryStylable.root, s.title);
    return (
      <Text tagName={htmlTag} className={classes} data-hook="slider-gallery-title">
        {globals.textsMap.sliderGalleryTitle}
      </Text>
    );
  }

  public render() {
    const {globals} = this.props;
    const {styles, stylesParams, isCategoryVisible, isEditorMode, textsMap, galleryType} = globals;

    const appClassNames = classNames(s.root, s.backgroundColor, {
      isRTL: globals.isRTL,
    });

    if (!isCategoryVisible && isEditorMode) {
      return (
        <EmptyGallery
          localeMap={{
            noProductsFilteredMessageText: '',
            noProductsMessageText: textsMap.noProductsMessageText,
            emptyCategoryEditorSubTitle: textsMap.emptyCategoryEditorSubTitle,
            emptyCategoryEditorTitle: textsMap.emptyCategoryEditorTitle,
          }}
          hasFilters={false}
        />
      );
    }

    if (!this.shouldShowSlider || this.props.hideGallery || (!isCategoryVisible && !isEditorMode)) {
      return null;
    }

    const isFullWidth = styles.get(stylesParams.full_width);
    const width = this.state.width || '100%';

    let sliderGalleryStyle = {};
    if (this.isResponsive) {
      sliderGalleryStyle = {...sliderGalleryStyle, width};
    }

    const titleShownByDefault =
      galleryType === GALLERY_TYPE.RELATED_PRODUCTS || galleryType === GALLERY_TYPE.ALGORITHMS;

    const rendered = this.isEmptyState ? this.renderEmptyState() : this.renderSlides();
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div
          data-hook="slider-gallery"
          data-slider-index={this.state.currentSliderIndex}
          style={sliderGalleryStyle}
          ref={(r) => (this._ref = r)}
          className={appClassNames}>
          <ConditionalRender by={'showTitleWasTurnedOn'}>{this.renderTitle()}</ConditionalRender>
          {titleShownByDefault && (
            <ConditionalRender by={'showTitleWasNeverChanged'}>{this.renderTitle()}</ConditionalRender>
          )}

          <div
            data-hook="slides-container"
            className={classNames(s.slidesContainer, {
              [s.fullWidth]: isFullWidth,
              [s.responsive]: this.isResponsive,
              [s.arrowLess]: this.shouldUseArrowlessMobileSlider,
            })}>
            {rendered}
          </div>
        </div>
      </>
    );
  }

  private updateSlidesKeyboardNavigation() {
    Array.from(getKeyboardFocusableElements(this._ref, '.slick-active')).forEach(
      (e: HTMLAnchorElement) => (e.tabIndex = 0)
    );
    Array.from(getKeyboardFocusableElements(this._ref, '.slick-slide:not(.slick-active)')).forEach(
      (e: HTMLAnchorElement) => (e.tabIndex = -1)
    );
  }

  private get shouldUseArrowlessMobileSlider(): boolean {
    if (this.isEmptyState) {
      return false;
    }

    const {
      globals: {
        shouldShowMobile,
        experiments: {isArrowlessMobileSliderEnabled},
      },
    } = this.props;

    return shouldShowMobile && isArrowlessMobileSliderEnabled;
  }

  private updateCurrentActiveSliderIndex() {
    setTimeout(() => {
      this.setState({
        currentSliderIndex: this.currentActiveSlideIndex,
      });
    });
  }
}

function getKeyboardFocusableElements(element: HTMLElement, ancestorSelector: string) {
  const tagSelectors = 'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
  return [...element.querySelectorAll(`${ancestorSelector} :is(${tagSelectors})`)].filter(
    (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
  );
}

export const SliderGallery = withGlobals(SliderGalleryComp);
